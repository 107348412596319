import {
  useEffect,
  useRef,
  useMemo,
  useSyncExternalStore,
} from 'react';
import { useSearchParams } from 'react-router-dom';

function usePrev(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
}

function subscribe(callback) {
  window.addEventListener('resize', callback);

  return () => {
    window.removeEventListener('resize', callback);
  };
}

function useDimensions(ref) {
  const dimensions = useSyncExternalStore(
    subscribe,
    () => JSON.stringify({
      width: ref.current?.offsetWidth ?? 0, // 0 is default width
      height: ref.current?.offsetHeight ?? 0, // 0 is default height
    }),
  );

  return useMemo(() => JSON.parse(dimensions), [dimensions]);
}

function useSearchParamValue(key) {
  const [searchParams] = useSearchParams();

  return searchParams.get(key);
}

function useIsScreen() {
  return useSearchParamValue('isScreen') === 'true';
}

function useShowWaitingScreen() {
  return useSearchParamValue('waitingScreen') !== 'false';
}

export {
  useDimensions,
  useIsScreen,
  usePrev,
  useOnClickOutside,
  useSearchParamValue,
  useShowWaitingScreen,
};