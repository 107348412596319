import distance from '@turf/distance';

import './stop-signals.styl';

const STOP_SIGNAL_WIDTH = 26;

function StopSignals({
  platformStartPoint,
  platformLength,
  stopSignals,
  trainDirection,
}) {
  return (
    <div className="stop-signals" style={{ flexDirection: trainDirection === 'LEFT' ? 'row' : 'row-reverse' }}>
      {
        stopSignals.map((stopSignal, index) => {
          if (!stopSignal.properties.refNumber) {
            return null;
          }

          // Figure out how far the stop signal is from the previous stop signal
          const prevPoint = index === 0 ? platformStartPoint : stopSignals[index - 1];
          const distanceToPrevPoint = distance(prevPoint, stopSignal, { units: 'meters' });

          // Percentage offset
          const offset = (distanceToPrevPoint / platformLength) * 100;

          // If the train is going left we have to use margin-left, if it's going right we have to use margin-right
          const marginLeft = trainDirection === 'LEFT' ? `calc(${offset}% - ${index === 0 ? 0 : STOP_SIGNAL_WIDTH}px)` : undefined;
          const marginRight = trainDirection === 'LEFT' ? undefined : `calc(${offset}% - ${index === 0 ? 0 : STOP_SIGNAL_WIDTH}px)`;

          return (
            <div
              className="stop-signals__signal"
              style={{
                marginLeft,
                marginRight,
                // Translate by either -50% or 50% of the plaque's width so that the center of the plaque is at the correct location
                transform: `translateX(${trainDirection === 'LEFT' ? '-50%' : '50%'})`,
              }}
              key={stopSignal._id}
            >
              {stopSignal.properties.ref}
            </div>
          );
        })
      }
    </div>
  );
}

export default StopSignals;