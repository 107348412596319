import DepartureDetails from '@components/DepartureDetails/DepartureDetails';
import PlatformInfo from '@App/components/PlatformInfo/PlatformInfo';
import WaitingScreen from '@App/components/WaitingScreen/WaitingScreen';

import { usePlatformInfo } from '@utils/QueryClient/platform-info';
import { useTranslation } from '@utils/i18n/i18n';
import {
  useIsScreen,
  useSearchParamValue,
  useShowWaitingScreen,
} from '@services/hooks.service';

import './base-page.styl';

function BasePage() {
  const { t } = useTranslation();

  const station = useSearchParamValue('station');
  const platform = useSearchParamValue('platform');
  const showWaitingScreen = useShowWaitingScreen();
  const isScreen = useIsScreen();
  const trainNumber = useSearchParamValue('trainNumber');

  const { data: platformInfo, isLoading, isError } = usePlatformInfo(station, platform, trainNumber);

  if (!station || (!platform && !trainNumber)) {
    return (
      <div className="base-page base-page--error">
        {t('Geen station/platform opgegeven')}
      </div>
    );
  }

  if (isError || (!isLoading && !platformInfo)) {
    return (
      <WaitingScreen />
    );
  }

  if (isLoading) {
    return (
      <div className="base-page is-loading" />
    );
  }

  const {
    departureInfo,
    layoutType,
    platformLength,
    platformMarkers,
    platformStartPoint,
    stopSignal,
    stopSignals,
    vehicleComposition,
  } = platformInfo;

  if (showWaitingScreen
    && (departureInfo.canceled || Date.now() <= departureInfo.departureTimestamp - (15 * 60 * 1000))) {
    return (
      <WaitingScreen />
    );
  }

  return (
    <div className="base-page">
      <div className={`base-page__content${isScreen ? ' base-page__content--screen' : ''}`}>
        <PlatformInfo
          composition={vehicleComposition}
          layoutType={layoutType}
          platform={departureInfo.platform}
          platformLength={platformLength}
          platformMarkers={platformMarkers}
          platformStartPoint={platformStartPoint}
          stopSignal={stopSignal}
          stopSignals={stopSignals}
        />
        <DepartureDetails
          delay={departureInfo.delay}
          departureTimestamp={departureInfo.departureTimestamp}
          destination={departureInfo.destination}
          platform={departureInfo.platform}
          via={departureInfo.via}
        />
      </div>
    </div>
  );
}

export default BasePage;