import TrainCar from '@App/components/TrainCar/TrainCar';

import TrainCompositionBlur from './TrainCompositionBlur/TrainCompositionBlur';
import { getFlexDirection, getMarginLeft, getMarginRight } from './train-composition.utils';

import './train-composition.styl';

function TrainComposition({
  composition,
  compositionOffset,
  compositionWidth,
  isScreen,
  trainDirection,
  showBlur,
}) {
  return (
    <div
      className={`train-composition${isScreen ? ' train-composition--screen' : ''}`}
      style={{
        flexDirection: getFlexDirection(isScreen, trainDirection),
        marginLeft: getMarginLeft(isScreen, compositionOffset, compositionWidth, trainDirection),
        marginRight: getMarginRight(isScreen, compositionOffset, compositionWidth, trainDirection),
        width: `${compositionWidth}%`,
      }}
    >
      {composition.map(({
        hasBikeSection,
        hasPrmSection,
        id,
        isLoco,
        orientation,
        isFirstClass,
        hasSeats,
        trainUnitType,
        inDirection,
      }, index) => {
        return (
          <TrainCar
            isScreen={isScreen}
            hasDirectionArrow={index === 0}
            hasBikeSection={hasBikeSection}
            hasPrmSection={hasPrmSection}
            hasSeats={hasSeats}
            isFirstClass={isFirstClass}
            isLoco={isLoco}
            key={id}
            orientation={orientation}
            trainUnitType={trainUnitType}
            inDirection={inDirection}
          />
        );
      })}
      {!isScreen && showBlur && <TrainCompositionBlur />}
    </div>
  );
}

export default TrainComposition;