import Icon from '@library/Icon/Icon';

import { getIconToUse, getTrainCarIcon, shouldFlipIcon } from './train-car.utils';

import './train-car.styl';

function TrainCar({
  hasBikeSection,
  hasDirectionArrow,
  hasPrmSection,
  hasSeats,
  inDirection,
  isFirstClass,
  isLoco,
  isScreen,
  orientation,
  trainUnitType,
}) {
  return (
    <div className="train-car">
      {!isScreen ? (
        <div className="train-car__utilities">
          {hasBikeSection && (
            <img src="/img/icons/icons_bike.svg" />
          )}
          {hasPrmSection && (
            <img src="/img/icons/icons_pmr.svg" />
          )}
        </div>
      ) : (
        <div className="train-car__direction">
          {hasDirectionArrow && (
            <Icon icon={getIconToUse(orientation, isScreen)} color="#0F3054" />
          )}
        </div>
      )}
      <div
        style={{
          position: 'relative',
          marginTop: !isScreen ? '6px' : '3px',
          marginBottom: !isScreen ? '3px' : '6px',
          display: 'flex',
        }}
      >
        <img
          className="train-car__icon"
          src={getTrainCarIcon(isLoco, hasSeats, isFirstClass, shouldFlipIcon(orientation, isScreen))}
          title={`trainUnitType: ${trainUnitType} - inDirection: ${inDirection}`}
        />
      </div>
      {!isScreen ? (
        <div className="train-car__direction">
          {hasDirectionArrow && (
            <Icon icon={getIconToUse(orientation, isScreen)} color="#0F3054" />
          )}
        </div>
      ) : (
        <div className="train-car__utilities">
          {hasBikeSection && (
            <img src="/img/icons/icons_bike.svg" />
          )}
          {hasPrmSection && (
            <img src="/img/icons/icons_pmr.svg" />
          )}
        </div>
      )}
    </div>
  );
}

export default TrainCar;