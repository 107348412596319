import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function usePlatformInfo(station, platform, trainNumber) {
  const queryParams = new URLSearchParams({
    station,
    platform,
    trainNumber,
  });

  return useQuery({
    queryKey: ['platform-info', station, platform],
    queryFn: async () => {
      const { data } = await axios.get(`/api/platform-info?${queryParams.toString()}`);

      return data;
    },
    enabled: !!station && (!!platform || !!trainNumber),
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
  });
}

function usePlatformMarkers(station, platform) {
  return useQuery({
    queryKey: ['platform-markers', station, platform],
    queryFn: async () => {
      const { data } = await axios.get(`/api/platform-info/markers?station=${station}&platform=${platform}`);

      return data;
    },
    enabled: !!station && !!platform,
  });
}

export {
  usePlatformInfo,
  usePlatformMarkers,
};