import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './icon.styl';

function Icon(props) {
  const pathToImage = `/img/icons/${props.icon}`;

  return (
    <span
      className={`icon${props.className ? ` ${props.className}` : ''}`}
      style={{
        height: (props.size && props.size.height) || props.size,
        width: (props.size && props.size.width) || props.size,
      }}
    >
      {typeof props.icon === 'object' && (
        <FontAwesomeIcon icon={props.icon} color={props.color} />
      )}
      {typeof props.icon !== 'object' && props.icon.endsWith('.svg') && (
        <ReactSVG
          src={pathToImage}
          beforeInjection={(svg) => {
            if (props.color) {
              svg.setAttribute('fill', props.color)
              const paths = svg.getElementsByTagName('path');
              for (let i = 0; i < paths.length; i += 1) {
                paths[i].removeAttribute('fill')
              }
            }
          }}
          afterInjection={(error) => {
            if (error) console.error(error);
          }}
        />
      )}
      {typeof props.icon !== 'object' && !props.icon.endsWith('.svg') && (
        <span
          className="icon__background-image"
          style={{
            backgroundImage: `url(${pathToImage})`,
          }}
        />
      )}
    </span>
  )
}

export default Icon;