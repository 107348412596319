import {
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import PlatformInfoZones from '@App/components/PlatformInfoZones/PlatformInfoZones';
import StopSignals from '@App/components/StopSignals/StopSignals';
import TrainComposition from '@App/components/TrainComposition/TrainComposition';
import { useIsScreen, useSearchParamValue } from '@services/hooks.service';
import { isHorizontallyScrollable } from '@services/utils.service';
import { useTranslation } from '@utils/i18n/i18n';

import { getTrainOffset } from './platform-info.utils';

import './platform-info.styl';

function PlatformInfo({
  composition,
  layoutType,
  platform,
  platformLength,
  platformMarkers,
  platformStartPoint,
  stopSignal,
  stopSignals,
}) {
  const [showBlur, setShowBlur] = useState(false);

  const { t } = useTranslation();
  const ref = useRef();
  const shouldScrollToStart = useRef(true);
  const isScreen = useIsScreen();
  const showStopSignals = useSearchParamValue('showStopSignals') === 'true';

  const trainLength = composition.reduce((acc, curr) => acc + curr.lengthInMeter, 0);

  const compositionWidth = (trainLength / platformLength) * 100;
  const compositionOffset = (getTrainOffset(platformStartPoint, stopSignal) / platformLength) * 100;
  const trainDirection = composition[0].orientation;

  const markersToUse = useMemo(
    () => {
      if (isScreen) {
        const copiedArray = platformMarkers.slice();
        return copiedArray.reverse();
      }

      return platformMarkers;
    },
    [isScreen, platformMarkers],
  );

  function onScroll() {
    setShowBlur(isHorizontallyScrollable(ref.current));
  }

  useEffect(() => {
    if (isScreen) {
      return;
    }

    if (shouldScrollToStart.current) {
      ref.current.scrollTo({ left: 0 });
      const basePageElBoundingClientRect = document.querySelector('.base-page').getBoundingClientRect();
      const trainCompositionElBoundingClientRect = document.querySelector('.train-composition').getBoundingClientRect();

      const pageWidth = basePageElBoundingClientRect.width;

      const direction = trainDirection.toLowerCase();
      const trainCompositionStart = trainCompositionElBoundingClientRect[direction];

      if (isHorizontallyScrollable(ref.current)) {
        const offset = direction === 'left' ? trainCompositionStart - 50 : trainCompositionStart - pageWidth + 50;

        if (offset > 0) {
          ref.current.scrollTo({
            left: offset,
            behavior: 'smooth',
          });
        }
      }
    }

    shouldScrollToStart.current = false;
  }, [trainDirection, isScreen]);

  useEffect(() => {
    onScroll();
    window.addEventListener('resize', onScroll);

    return () => window.removeEventListener('resize', onScroll);
  }, []);

  return (
    <div className="platform-info">
      {!isScreen && (
        <h3 className="platform-info__title title-text">
          {t('Treinsamenstelling en zones op het perron')}
        </h3>
      )}
      <ScrollContainer innerRef={ref} vertical={false} onScroll={() => onScroll()}>
        <div className={`platform-info__composition${isScreen ? ' platform-info__composition--screen' : ''}`}>
          <TrainComposition
            composition={composition}
            trainDirection={trainDirection}
            compositionWidth={compositionWidth}
            compositionOffset={compositionOffset}
            isScreen={isScreen}
            showBlur={showBlur}
          />
          {showStopSignals && (
            <StopSignals
              platformLength={platformLength}
              platformStartPoint={platformStartPoint}
              stopSignals={stopSignals}
              trainDirection={trainDirection}
            />
          )}
          <PlatformInfoZones
            isScreen={isScreen}
            layoutType={layoutType}
            platform={platform}
            platformLength={platformLength}
            platformMarkers={markersToUse}
            platformStartPoint={platformStartPoint}
            trainDirection={trainDirection}
          />
        </div>
      </ScrollContainer>
    </div>
  );
}

export default PlatformInfo;