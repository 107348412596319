import { useEffect, useState } from 'react';

import './train-composition-blur.styl';

function TrainCompositionBlur() {
  const [blurHeight, setBlurHeight] = useState(0);

  useEffect(() => {
    function update(entries) {
      for (const entry of entries) {
        setBlurHeight(entry.contentRect.height);
      }
    }

    const observer = new ResizeObserver(update);
    observer.observe(document.querySelector('.train-composition'));

    return () => observer.disconnect();
  }, []);

  return (
    <div
      className="train-composition-blur"
      style={{
        height: `${blurHeight}px`,
      }}
    />
  );
}

export default TrainCompositionBlur;