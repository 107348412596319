import distance from '@turf/distance';

import './platform-info-zones.styl';

const PLAQUE_WIDTH = 56;

function PlaqueLayout({
  platform,
  platformLength,
  platformMarkers,
  platformStartPoint,
  trainDirection,
}) {
  return (
    <div className="plaque-layout" style={{ flexDirection: trainDirection === 'LEFT' ? 'row' : 'row-reverse' }}>
      {platformMarkers.map((platformMarker, index) => {
        // Figure out how far the stop signal is from the previous stop signal
        const prevPoint = index === 0 ? platformStartPoint : platformMarkers[index - 1];
        const distanceToPrevPoint = distance(prevPoint, platformMarker, { units: 'meters' });

        // Percentage offset
        const offset = (distanceToPrevPoint / platformLength) * 100;

        // If the train is going left we have to use margin-left, if it's going right we have to use margin-right
        const marginLeft = trainDirection === 'LEFT' ? `calc(${offset}% - ${index === 0 ? 0 : PLAQUE_WIDTH}px)` : undefined;
        const marginRight = trainDirection === 'LEFT' ? undefined : `calc(${offset}% - ${index === 0 ? 0 : PLAQUE_WIDTH}px)`;

        return (
          <div
            className="plaque-layout__plaque"
            key={platformMarker.id}
            style={{
              marginLeft,
              marginRight,
              // Translate by either -50% or 50% of the plaque's width so that the center of the plaque is at the correct location
              transform: `translateX(${trainDirection === 'LEFT' ? '-50%' : '50%'})`,
            }}
          >
            <span className="plaque-layout__plaque__zone">
              Zone
            </span>
            <span className="plaque-layout__plaque__letter" style={{ letterSpacing: 1 }}>
              {platformMarker.properties.ref.replaceAll(platform, '').replace(';', '|')}
            </span>
          </div>
        );
      })}
    </div>
  );
}

function SectionedLayout({
  isScreen,
  platform,
  platformLength,
  platformMarkers,
}) {
  return (
    <div className="platform-info-zones">
      {platformMarkers.map((platformMarker, index) => {
        if (index === platformMarkers.length - 1) {
          return null;
        }

        const firstMarker = platformMarker;
        const secondMarker = platformMarkers[index + 1];

        const zoneWidth = distance(firstMarker, secondMarker, { units: 'meters' });
        const zoneWidthPercentage = `${(zoneWidth / platformLength) * 100}%`;

        const refSplit = firstMarker.properties.ref.split(';');
        const zoneName = refSplit[isScreen ? 0 : refSplit.length - 1];

        return (
          <div
            className="platform-info-zones__zone"
            key={platformMarker._id}
            style={{
              width: zoneWidthPercentage,
            }}
          >
            {`Zone ${zoneName.replace(platform, '')}`}
          </div>
        )
      })}
    </div>
  )
}

function PlatformInfoZones({
  isScreen,
  layoutType,
  platform,
  platformLength,
  platformMarkers,
  platformStartPoint,
  trainDirection,
}) {
  if (layoutType === 'sectioned') {
    return (
      <SectionedLayout
        platform={platform}
        platformMarkers={platformMarkers}
        platformLength={platformLength}
        isScreen={isScreen}
      />
    );
  }

  return (
    <PlaqueLayout
      platform={platform}
      platformLength={platformLength}
      platformMarkers={platformMarkers}
      platformStartPoint={platformStartPoint}
      trainDirection={trainDirection}
    />
  );
}

export default PlatformInfoZones;